.SelectionArea {
	background: #EA5656;
	margin: 20px;
	width: 250px;
	color: white;
	border-radius: 4px;
	cursor: pointer;
}

.SelectionArea:hover {
	background: #CA3B52;
}